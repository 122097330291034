/* You can add global styles to this file, and also import other style files */
@import "./app/_common_v3.scss";


* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html,
body {
    font-family: $v2_font_family_general !important;
    margin: 0;
    padding: 0;
    background-color: var(--v2_primary_background_color_1);
    color: var(--v2_text_color_1);
}

// apply theme styles
body {
    &.theme-light {

        // background colors
        --v2_primary_background_color_1: #fef6e4;
        --v2_primary_background_color_1_rgb: 254, 246, 228;
        --v2_primary_background_star_color: #384734;

        // text colors
        --v2_text_color_1: #172C66;
        --v2_text_color_1_darken: #122352;
        --v2_text_heading_underline: #384734;

        // theme colors
        --v2_main_color_1: #a3dcd4; // lighter than background
        --v2_main_color_1_rgb: 163, 220, 212;
        --v2_main_color_1_lighten: #b5e3dd; // lighter that main
        --v2_main_color_1_lighter_than_lighter: #c8eae5; // very light version
        --v2_main_color_1_darken: #93c6bf; // background

        // logo colors
        --v2_logo_background: #b5e3dd; // lighten

    }

    &.theme-dark {
        // background colors
        --v2_primary_background_color_1: #0e1b2b;
        --v2_primary_background_color_1_rgb: 14, 27, 43;
        --v2_primary_background_star_color: #27DEBF;

        // text colors
        --v2_text_color_1: #FFFFFF;
        --v2_text_color_1_darken: #cccccc;
        --v2_text_heading_underline: #27DEBF;

        // theme colors
        --v2_main_color_1: #263240; // lighter than background
        --v2_main_color_1_rgb: 38, 50, 64;
        --v2_main_color_1_lighten: #3e4955; // lighter that main
        --v2_main_color_1_lighter_than_lighter: #565f6b; // very light version
        --v2_main_color_1_darken: #0E1B2B; // background

        // logo colors
        --v2_logo_background: #3e4955; // lighten
    }

    &.theme-common {

        // third party icon colors
        --v2_twitter_blue: #03A9F4;
        --v2_linkedin_blue: #378fe9;
        --v2_gmail_wheat: #E7E4D7;

        // error success colors
        --v2_error_red: #8B0000;
        --v2_heart_red: #ff6e6e;


    }
}

// apply generic styles
body {
    p {
        margin: 0;
    }

    overflow: auto;

    &.sidebarActive {
        overflow: hidden;
    }

    #external-link-button {
        display: none;
    }
}