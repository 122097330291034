// Declare some custom fonts
@font-face {
    font-family: 'Caranda';
    src: url('../assets/Fonts/caranda/Caranda.ttf') format('truetype'),
}

@font-face {
    font-family: 'Dosis';
    src: url('../assets/Fonts/Dosis/static/Dosis-Regular.ttf') format('truetype'),
}

// font family
$v2_font_family_my_name: 'Caranda';
$v2_font_family_general: 'Dosis';